import styled from 'styled-components'
import {
  compose,
  background,
  space,
  layout,
  color,
  border,
  typography,
  system,
} from 'styled-system'
import AntdInput from 'antd/es/input'
import AntdSelect from 'antd/es/select'
import AntdRadio from 'antd/es/radio'
import AntdCheckbox from 'antd/es/checkbox'
import AntdTimePicker from 'antd/es/time-picker'
import AntdDatePicker from 'antd/es/date-picker'
import AntdInputNumber from 'antd/es/input-number'
import AntdSwitch from 'antd/es/switch'
import AntdTextArea from 'antd/es/input/TextArea'
import 'antd/es/input/style/index.css'
import 'antd/es/radio/style/index.css'
import 'antd/es/select/style/index.css'
import 'antd/es/checkbox/style/index.css'
import 'antd/es/time-picker/style/index.css'
import 'antd/es/date-picker/style/index.css'
import 'antd/es/input-number/style/index.css'
import 'antd/es/switch/style/index.css'
import 'antd/es/input/TextArea'

const { RangePicker: AntRangePicker } = AntdDatePicker

const fontSize = system({
  fontSize: {
    property: `fontSize`,
    scale: `fontSizes`,
  },
})

const placeholderStyles = system({
  placeholderFontSize: {
    property: `fontSize`,
    scale: `fontSizes`,
  },
  placeholderFontStyle: {
    property: `fontStyle`,
  },
  placeholderColor: {
    property: `color`,
    scale: `colors`,
  },
})

const Input = styled(AntdInput)`
  &.ant-input {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${placeholderStyles}
    }
  }
`
const TextArea = styled(AntdTextArea)`
  &.ant-input {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${placeholderStyles}
    }
  }
`
const InputNumber = styled(AntdInputNumber)`
  &.ant-input-number {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
  & .ant-input-number-input {
    ${fontSize}
  }
`

InputNumber.defaultProps = {
  fontSize: `1`,
}

const InputPassword = styled(AntdInput.Password)`
  &.ant-input-password .ant-input {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${placeholderStyles}
    }
  }
`

const Select = styled(AntdSelect)`
  width: 100%;
  & .ant-select-selection.ant-select-selection--single,
  & .ant-select-selection.ant-select-selection--multiple {
    width: 100%;
    border-radius: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    ${compose(background, layout, space, color, border, typography)}
    & .ant-select-selection__placeholder {
      ${color}
    }
  }
  & .ant-select-search--inline .ant-select-search__field {
    ${color}
    width: auto;
  }
`

const Radio = styled(AntdRadio)`
  &.ant-radio-group {
    width: 100% !important;
    border-radius: 0;
    line-height: 1;
    ${compose(background, layout, space, color, border, typography)}
    &::placeholder {
      ${color}
    }
  }
`
const Checkbox = styled(AntdCheckbox)`
  width: 100% !important;
  &.ant-checkbox-wrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
      ${color}
    }

    > span:last-child {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
  }
`

Checkbox.defaultProps = {
  backgroundColor: `primary.2`,
}

const TimePicker = styled(AntdTimePicker)`
  &.ant-time-picker {
    width: 100% !important;
  }
`
const DatePicker = styled(AntdDatePicker)`
  &.ant-calendar-picker {
    width: 100% !important;
    & .ant-calendar-picker-input.ant-input {
      ${color}
      & ::placeholder {
        ${color}
      }
    }
  }
`
DatePicker.defaultProps = {
  color: `gray.1`,
}

const Switch = styled(AntdSwitch)`
  &.ant-switch.ant-switch-checked {
    ${color}
  }
`
Switch.defaultProps = {
  backgroundColor: `primary.2`,
}
const RangePicker = styled(AntRangePicker)``

export {
  Input,
  InputNumber,
  InputPassword,
  Select,
  Radio,
  Checkbox,
  TimePicker,
  DatePicker,
  Switch,
  RangePicker,
  TextArea,
}
